import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Typography from '../components/Typography'

import { makeStyles } from '@material-ui/core/styles'
import colors from '../variables/colors'
import compStyles from '../pageComponents/exploseCourses/exploreCourses.module.scss'

const useStyles = makeStyles({
  heading: {
    marginTop: '20px',
    textAlign: 'center',
    padding: '40px 0 10px'
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px auto'
  },
  tab: {
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    border: '1px solid #611a87',
    borderRadius: '4px',
    margin: '0 20px',
    cursor: 'pointer'
  },
  tabSelected: {
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: '10px',
    borderRadius: '4px',
    margin: '0 20px',
    cursor: 'pointer'
  },
  skillContent: {
    maxWidth: '1200px',
    margin: '40px auto'
  },
  description: {
    textAlign: 'center',
    margin: '10px'
  },
  titleContainer: {
    height: '300px',
    overflow: 'hidden',
    position: 'relative'
  },
  background: {
    width: '100%',
    position: 'absolute',
    bottom: '0',
    
    '@media screen and (min-width: 799px)': {
      bottom: '400px',
    }
  },
  titleBar: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    top: '50px',
    
    '@media screen and (min-width: 799px)': {
      top: '30px'
    }
  },
  title: {
    color: '#fff',
    margin: '10px',
    fontSize: '2em',
    '@media screen and (min-width: 799px)': {
      fontSize: '3em',
    }
  },
  titleImage: {
    width: '120px',
    
    '@media screen and (min-width: 799px)': {
      width: '220px',
    }
  },
  skillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  skill: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '320px',
    backgroundColor: colors.primary,
    textAlign: 'center',
    padding: '10px',
    borderRadius: '5px',
    margin: '10px',
    minHeight: '60px',
    '@media screen and (min-width: 799px)': {
      width: '400px',
      padding: '15px',
      margin: '10px 20px',
    }
  },
  skillName: {
    color: '#fff',
    lineHeight: '1.1',
    fontStyle: 'italic'
  },
  requirementsSection: {
    margin: '60px 20px 40px'
  },
  requirements: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  requirementTitle: {
    textAlign: 'center'
  },
  requirementItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px'
  },
  requirementImage: {
    marginRight: '20px'
  }
})

const guiterSkills = [
  'How to hold a guitar pick',
  'Proper fret hand position',
  'Basic guitar chords',
  'Guitar tuning and instrument care',
  'Establishing good practice habits',
  'Reading sheet music',
  'Fun with timing and groove',
  'Understanding time signatures',
  'Specific skills and techniques'
]

const pianoSkills = [
  'Build awareness of Steady Beat, Tempo',
  'Understand difference in Volume and Pitch',
  'Learn to focus on the stability of the note and match pitch perfectly',
  'Understand how to listen to music',
  'Make student acquainted to White & Black Keys on the Piano with all the Alphabets',
  'Introduce Hand Position (C, A, E, G)'
]

const vocalSkills = [
  'Understanding your Voice', 
  'Singing Posture',
  'Understanding Pitch- Different types of Pitch, how to identify and use Correctly',  
  'Vocal Training - Voice exercises using different Pitch and Rhythm techniques',  
  'Understanding Voice Modulation',  
  'Fundamentals of Music - Elements of Music, Note Values, How to Read basic Music Notation',  
  'Scales and other music building techniques & much more..!!'
]

const ExploreCoursesPage = (props) => {
  const classes = useStyles();
  const type = props.location.search.includes('1') ? 1 : props.location.search.includes('2') ? 2 : 0
  const [value, setValue] = React.useState(type);

  console.log(props.location)

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const images = useStaticQuery(graphql`
    query {
      guiterPurple: file(relativePath: { eq: "courses/guiter_purple.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      guiterTurquoise: file(relativePath: { eq: "courses/guiter_turquoise.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pianoPurple: file(relativePath: { eq: "courses/piano_purple.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pianoTurquoise: file(relativePath: { eq: "courses/piano_turquoise.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      vocalPurple: file(relativePath: { eq: "courses/vocal_purple.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      vocalTurquoise: file(relativePath: { eq: "courses/vocal_turquoise.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      laptop: file(relativePath: { eq: "courses/laptop.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      pianoGuiter: file(relativePath: { eq: "courses/piano_guiter.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      internet: file(relativePath: { eq: "courses/internet.png" }) {
        childImageSharp {
          fixed(width: 50 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      guiterSkill: file(relativePath: { eq: "courses/guiter_turquoise.png" }) {
        childImageSharp {
          fluid(maxWidth: 240 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      pianoSkill: file(relativePath: { eq: "courses/piano_turquoise.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      vocalSkill: file(relativePath: { eq: "courses/vocal_turquoise.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      background: file(relativePath: { eq: "main/background1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2400 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
  <Layout>
    <SEO title='Explore Courses' />
    <div>
      <Typography variant="h3" className={classes.heading}>LEARN WHAT FACINATES YOU</Typography>
    </div>
    <div className={classes.tabs}>
      <div className={value === 0 ? classes.tabSelected : compStyles.tab} onClick={() => handleChange(0) }>
        { value === 1 || value === 2 ? (<React.Fragment>
          <Img fixed={images.guiterPurple.childImageSharp.fixed} className={compStyles.selectTab} />
          <Img fixed={images.guiterTurquoise.childImageSharp.fixed} className={compStyles.selectTabHover} />
        </React.Fragment>) : (<Img fixed={images.guiterTurquoise.childImageSharp.fixed} />) }
      </div>
      <div className={value === 1 ? classes.tabSelected : compStyles.tab} onClick={() => handleChange(1) }>
        { value === 0 || value === 2 ? (<React.Fragment>
          <Img fixed={images.pianoPurple.childImageSharp.fixed} className={compStyles.selectTab} />
          <Img fixed={images.pianoTurquoise.childImageSharp.fixed} className={compStyles.selectTabHover} />
        </React.Fragment>) : (<Img fixed={images.pianoTurquoise.childImageSharp.fixed} />) }
      </div>
      <div className={value === 2 ? classes.tabSelected : compStyles.tab} onClick={() => handleChange(2) }>
        { value === 0 || value === 1 ? (<React.Fragment>
          <Img fixed={images.vocalPurple.childImageSharp.fixed} className={compStyles.selectTab} />
          <Img fixed={images.vocalTurquoise.childImageSharp.fixed} className={compStyles.selectTabHover} />
        </React.Fragment>) : (<Img fixed={images.vocalTurquoise.childImageSharp.fixed} />) }
      </div>
    </div>
    <section hidden={value !== 0 }>
      <div className={classes.titleContainer}>
        <Img fluid={images.background.childImageSharp.fluid} className={classes.background} />
        <div className={classes.titleBar}>
          <Typography variant="h2" className={classes.title}>GUITAR FOUNDATION</Typography>
          <Img fluid={images.guiterSkill.childImageSharp.fluid} className={classes.titleImage} />
        </div>
      </div>
      <div className={classes.skillContent}>
        <Typography variant="body2" className={classes.description}>Our beginner lessons are perfect for anyone looking to learn basic guitar concepts — from playing your first chords and simple music theory to practice plans that keep you motivated. For true beginners, we start you with the absolute basics like tuning and getting to know your guitar. For players who might already have a foundation, we can help you pick up wherever you left off and fill in the gaps along the way.
        </Typography>
        <div className={classes.skillsContainer}>
          {
          guiterSkills.map((skill, i) => (<div key={i} className={classes.skill}>
            <Typography variant="body2" className={classes.skillName}>{skill}</Typography>
          </div>))
          }
        </div>
      </div>
    </section>
    <section hidden={value !== 1 }>
      <div className={classes.titleContainer}>
        <Img fluid={images.background.childImageSharp.fluid} className={classes.background} />
        <div className={classes.titleBar}>
          <Typography variant="h2" className={classes.title}>PIANO FOUNDATION</Typography>
          <Img fluid={images.pianoSkill.childImageSharp.fluid} className={classes.titleImage} />
        </div>
      </div>
      <div className={classes.skillContent}>
        <Typography variant="body2" className={classes.description}>This course is ideal for students looking to take their first steps into the world of Western Classical Music (Piano). The course aims at providing a strong foundation where learning is a process of constant discovery. The course will lay the foundation for acquiring clarity of concepts of Steady Beat, Rhythm, Tempo, Pitch, Volume and Note Values building the foundation of any music student, who wants to learn any instrument. The student develops listening, reading & playing skills with an exploratory outlook in addition to acquiring knowledge. The Course is designed for absolute beginners in music who can definitely become a good listener and nurture the joy of music.
        </Typography>
        <div className={classes.skillsContainer}>
          {
          pianoSkills.map((skill, i) => (<div key={i} className={classes.skill}>
            <Typography variant="body2" className={classes.skillName}>{skill}</Typography>
          </div>))
          }
        </div>
      </div>
    </section>
    <section hidden={value !== 2 }>
      <div className={classes.titleContainer}>
        <Img fluid={images.background.childImageSharp.fluid} className={classes.background} />
        <div className={classes.titleBar}>
          <Typography variant="h2" className={classes.title}>VOCAL FOUNDATION</Typography>
          <Img fluid={images.vocalSkill.childImageSharp.fluid} className={classes.titleImage} />
        </div>
      </div>
      <div className={classes.skillContent}>
        <Typography variant="body2" className={classes.description}>Vocal foundations is all about focusing on key areas of the voice, rounding out the trouble spots in your voice, and setting a vocal foundation that we can build upon. This starts with taking an honest evaluation of the voice and then giving you the best techniques to start improving immediately.
        </Typography>
        <div className={classes.skillsContainer}>
          {
          vocalSkills.map((skill, i) => (<div key={i} className={classes.skill}>
            <Typography variant="body2" className={classes.skillName}>{skill}</Typography>
          </div>))
          }
        </div>
      </div>
    </section>
    <section className={classes.requirementsSection}>
      <Typography variant="body1" className={classes.requirementTitle}>Requirement</Typography>
      <div className={classes.requirements}>
        {
          value === 2 ? null : (<div className={classes.requirementItem}><Img fixed={images.pianoGuiter.childImageSharp.fixed} className={classes.requirementImage} /><Typography variant="body2">Instrument</Typography></div>)
        }
        <div className={classes.requirementItem}><Img fixed={images.laptop.childImageSharp.fixed} className={classes.requirementImage} /><Typography variant="body2">Access to Computer/Laptop</Typography></div>
        <div className={classes.requirementItem}><Img fixed={images.internet.childImageSharp.fixed} className={classes.requirementImage} /><Typography variant="body2">Internet Connection</Typography></div>
      </div>
    </section>
  </Layout>
  )
}

export default ExploreCoursesPage
